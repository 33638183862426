<template>
  <aside>
    <header>
      <h2>Select a Gift</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      
      <ul class="product-list" v-if="products !== null">
        <li>
          <label>
            <input type="radio" name="product" value="points" v-model="selected">
            <i></i>

            <div class="details">
              <div>
                <h4>Points to spend</h4>
                <input type="text" placeholder="Enter points..." v-model.number="point_award" class="form-control form-control-sm">
              </div>
            </div>
          </label>
        </li>
        <li v-for="product in products" :key="`product_${product.id}`">
          <label>
            <input type="radio" name="product" :value="product.id" v-model="selected">
            <i></i>

            <div class="details">
              <div class="thumbnail" :style="{'background-image': `url('https://img-cdn.hithrive.com/storefront-images/${product.images[0]}.jpg')`}"></div>
              <div>
                <h4>{{product.name}}</h4>
                <div class="availability">{{country_availability(product)}}</div>
              </div>
            </div>
          </label>
        </li>
      </ul>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :disabled="selected === null" @click="save">Select gift</button>
    </footer>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      products: null,
      point_award: ''
    }
  },
  mounted() {
    this.populateProducts()
  },
  methods: {
    country_availability(product) {
      if(product.countries.length === 0) return 'All countries';
      return product.countries.map(c => this.$geoData.all_countries[c]).join(', ');
    },
    close() {
      this.$emit('done');
    },
    save() {
      if(this.selected === 'points') return this.$emit('done', {type: 'POINTS', points: this.point_award});
      
      this.$emit('done', {type: 'CUSTOM_REWARD', ...this.products.find(p => p.id === this.selected)})
    },
    async populateProducts() {
      const resp = await this.$api.Rewards.get_custom_rewards()
      this.products = resp;
    },
  }
}
</script>

<style lang="scss" scoped>
.product-list {
  display: block;
  padding: 0;
  margin: 0;

  > li {
    display: block;
    padding: 0;
    margin: 0;

    > label {
      background: #fff;
      border-radius: 4px;
      padding: 15px;
      overflow: hidden;
      display: flex;
      border: 1px solid $light;
      cursor: pointer;
      align-items: center;

      &:hover {
        border: 1px solid darken($light, 15%);
        box-shadow: rgba($body-color, 0.05) 0 3px 5px;
      }

      > input {
        position: absolute;
        left: -9999px;
        + i {
          width: 18px;
          height: 18px;
          border: 1px solid $light;
          border-radius: 100%;
          display: block;
          font-style: normal;

          &:before {
            content: '\f00c';
            display: block;
            line-height: 18px;
            font-size: 9px;
            font-weight: 700;
            color: #fff;
            font-family: 'Font Awesome 5 Pro';
            text-align: center;
            width: 18px;
            height: 18px;
            margin: 0 0 0 -1px;
          }
        }

        &:checked {
          + i {
            background: $link-color;
            border: 1px solid $link-color;
          }
        }
      }

      > .details {
        display: flex;
        padding: 0 0 0 15px;
        align-items: center;

        h4 {
          margin: 0;
          font-weight: 700;
          font-size: 18px;
        }

        > .thumbnail {
          display: block;
          width: 80px;
          height: 80px;
          background-size: cover;
          background-position: center center;
          border-radius: 6px;
          margin: 0 15px 0 0;
        }

        .availability {
          font-size: 10px;
          color: $muted-text;
        }

        .point-cost {
          display: block;

          > span {
            display: inline-block;
            background: $green;
            color: #fff;
            border-radius: 3px;
            line-height: 14px;
            font-size: 12px;
            padding: 2px 5px;
          }
        }
      }
    }
  }
}
</style>